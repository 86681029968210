import React from "react";
import "./ContactUs.css"; // Assuming the CSS is in this file
import markerIcon from '../../Resource/Innodhee-loco.png'

const ContactUs = () => {
    return (
        <section className="contact-section">
            {/* Google Map Container */}
            <div className="map-container">
                <iframe
                    title="Google Map"
                    src="https://maps.google.com/maps?q=12.9243866,77.6508897&hl=en&z=17&output=embed&center=12.9243866,77.647"
                    allowFullScreen=""
                    loading="lazy"
                    className="google-map"
                ></iframe>
            </div>

            {/* Contact Form Container */}
            <div className="contact-form-container">
                {/* Contact Details */}
                <div className="contact-details">
                    <p><b>Address:</b> Sarjapur Road, construction, Bengaluru, India</p>
                    <p><b>Phone:</b> (123) 456-7890</p>
                    <p><b>Email:</b> customer-support@innodhee.com</p>
                </div>

                {/* Contact Form */}
                <div className="contact-form">
                    <div className="input-field">
                        <input type="text" placeholder="Your Name" />
                    </div>
                    <div className="input-field">
                        <input type="email" placeholder="Your Email" />
                    </div>
                    <div className="input-field">
                        <textarea placeholder="Your Message"></textarea>
                    </div>
                    <button className="send-btn">Send</button>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
