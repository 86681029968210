import React, { useEffect, useState } from 'react';
import './Alert.css'; // Import the CSS file

const Alert = ({ message, type, duration = 3000, onClose }) => {
    const [isVisible, setIsVisible] = useState(true);

    // Auto-close the alert after a specified duration
    useEffect(() => {
        if (duration) {
            const timer = setTimeout(() => {
                setIsVisible(false);
                onClose && onClose();
            }, duration);
            return () => clearTimeout(timer);
        }
    }, [duration, onClose]);

    // Close the alert when clicking the close button
    const handleClose = () => {
        setIsVisible(false);
        onClose && onClose();
    };

    if (!isVisible) return null;

    return (
        <div className={`alert-box ${type}`}>
            <span>{message}</span>
            <button className="close-btn" onClick={handleClose}>×</button>
        </div>
    );
};

export default Alert;
