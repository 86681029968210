import React, { useState } from 'react';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook

function RegistrationForm({ formData, setFormData }) {
    const { showAlert } = useAlert();

    const countryTimezones = [
        { countryCode: 'AS', country: 'American Samoa', timezone: 'Pacific/Pago_Pago', offset: 'UTC -11:00' },
        { countryCode: 'NU', country: 'Niue', timezone: 'Pacific/Niue', offset: 'UTC -11:00' },
        { countryCode: 'UM', country: 'United States Minor Outlying Islands', timezone: 'Pacific/Midway', offset: 'UTC -11:00' },
        { countryCode: 'CK', country: 'Cook Islands', timezone: 'Pacific/Rarotonga', offset: 'UTC -10:00' },
        { countryCode: 'PF', country: 'French Polynesia', timezone: 'Pacific/Tahiti', offset: 'UTC -10:00' },
        { countryCode: 'US', country: 'United States', timezone: 'Pacific/Honolulu', offset: 'UTC -10:00' },
        { countryCode: 'PF', country: 'French Polynesia', timezone: 'Pacific/Marquesas', offset: 'UTC -09:30' },
        { countryCode: 'PF', country: 'French Polynesia', timezone: 'Pacific/Gambier', offset: 'UTC -09:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Adak', offset: 'UTC -09:00' },
        { countryCode: 'PN', country: 'Pitcairn', timezone: 'Pacific/Pitcairn', offset: 'UTC -08:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Anchorage', offset: 'UTC -08:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Juneau', offset: 'UTC -08:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Metlakatla', offset: 'UTC -08:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Nome', offset: 'UTC -08:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Sitka', offset: 'UTC -08:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Yakutat', offset: 'UTC -08:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Creston', offset: 'UTC -07:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Dawson', offset: 'UTC -07:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Dawson_Creek', offset: 'UTC -07:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Fort_Nelson', offset: 'UTC -07:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Vancouver', offset: 'UTC -07:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Whitehorse', offset: 'UTC -07:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Hermosillo', offset: 'UTC -07:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Mazatlan', offset: 'UTC -07:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Tijuana', offset: 'UTC -07:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Los_Angeles', offset: 'UTC -07:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Phoenix', offset: 'UTC -07:00' },
        { countryCode: 'BZ', country: 'Belize', timezone: 'America/Belize', offset: 'UTC -06:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Cambridge_Bay', offset: 'UTC -06:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Edmonton', offset: 'UTC -06:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Inuvik', offset: 'UTC -06:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Regina', offset: 'UTC -06:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Swift_Current', offset: 'UTC -06:00' },
        { countryCode: 'CR', country: 'Costa Rica', timezone: 'America/Costa_Rica', offset: 'UTC -06:00' },
        { countryCode: 'EC', country: 'Ecuador', timezone: 'Pacific/Galapagos', offset: 'UTC -06:00' },
        { countryCode: 'SV', country: 'El Salvador', timezone: 'America/El_Salvador', offset: 'UTC -06:00' },
        { countryCode: 'GT', country: 'Guatemala', timezone: 'America/Guatemala', offset: 'UTC -06:00' },
        { countryCode: 'HN', country: 'Honduras', timezone: 'America/Tegucigalpa', offset: 'UTC -06:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Bahia_Banderas', offset: 'UTC -06:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Chihuahua', offset: 'UTC -06:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Ciudad_Juarez', offset: 'UTC -06:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Merida', offset: 'UTC -06:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Mexico_City', offset: 'UTC -06:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Monterrey', offset: 'UTC -06:00' },
        { countryCode: 'NI', country: 'Nicaragua', timezone: 'America/Managua', offset: 'UTC -06:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Boise', offset: 'UTC -06:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Denver', offset: 'UTC -06:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Eirunepe', offset: 'UTC -05:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Rio_Branco', offset: 'UTC -05:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Atikokan', offset: 'UTC -05:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Rankin_Inlet', offset: 'UTC -05:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Resolute', offset: 'UTC -05:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Winnipeg', offset: 'UTC -05:00' },
        { countryCode: 'KY', country: 'Cayman Islands', timezone: 'America/Cayman', offset: 'UTC -05:00' },
        { countryCode: 'CL', country: 'Chile', timezone: 'Pacific/Easter', offset: 'UTC -05:00' },
        { countryCode: 'CO', country: 'Colombia', timezone: 'America/Bogota', offset: 'UTC -05:00' },
        { countryCode: 'EC', country: 'Ecuador', timezone: 'America/Guayaquil', offset: 'UTC -05:00' },
        { countryCode: 'JM', country: 'Jamaica', timezone: 'America/Jamaica', offset: 'UTC -05:00' },
        { countryCode: 'MX', country: 'Mexico', timezone: 'America/Cancun', offset: 'UTC -05:00' },
        { countryCode: 'PA', country: 'Panama', timezone: 'America/Panama', offset: 'UTC -05:00' },
        { countryCode: 'PE', country: 'Peru', timezone: 'America/Lima', offset: 'UTC -05:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Chicago', offset: 'UTC -05:00' },
        { countryCode: 'LC', country: 'Saint Lucia', timezone: 'America/St_Lucia', offset: 'UTC -04:00' },
        { countryCode: 'MF', country: 'Saint Martin (French part)', timezone: 'America/Marigot', offset: 'UTC -04:00' },
        { countryCode: 'VC', country: 'Saint Vincent and the Grenadines', timezone: 'America/St_Vincent', offset: 'UTC -04:00' },
        { countryCode: 'SX', country: 'Sint Maarten (Dutch part)', timezone: 'America/Lower_Princes', offset: 'UTC -04:00' },
        { countryCode: 'TT', country: 'Trinidad and Tobago', timezone: 'America/Port_of_Spain', offset: 'UTC -04:00' },
        { countryCode: 'TC', country: 'Turks and Caicos Islands', timezone: 'America/Grand_Turk', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Detroit', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Indiana/Indianapolis', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Indiana/Marengo', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Indiana/Petersburg', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Indiana/Vevay', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Indiana/Vincennes', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Indiana/Winamac', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Kentucky/Louisville', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/Kentucky/Monticello', offset: 'UTC -04:00' },
        { countryCode: 'US', country: 'United States', timezone: 'America/New_York', offset: 'UTC -04:00' },
        { countryCode: 'VE', country: 'Venezuela, Bolivarian Republic of', timezone: 'America/Caracas', offset: 'UTC -04:00' },
        { countryCode: 'VG', country: 'Virgin Islands, British', timezone: 'America/Tortola', offset: 'UTC -04:00' },
        { countryCode: 'VI', country: 'Virgin Islands, U.S.', timezone: 'America/St_Thomas', offset: 'UTC -04:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Palmer', offset: 'UTC -03:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Rothera', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Buenos_Aires', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Catamarca', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Cordoba', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Jujuy', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/La_Rioja', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Mendoza', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Rio_Gallegos', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Salta', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/San_Juan', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/San_Luis', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Tucuman', offset: 'UTC -03:00' },
        { countryCode: 'AR', country: 'Argentina', timezone: 'America/Argentina/Ushuaia', offset: 'UTC -03:00' },
        { countryCode: 'BM', country: 'Bermuda', timezone: 'Atlantic/Bermuda', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Araguaina', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Bahia', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Belem', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Fortaleza', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Maceio', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Recife', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Santarem', offset: 'UTC -03:00' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Sao_Paulo', offset: 'UTC -03:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Glace_Bay', offset: 'UTC -03:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Goose_Bay', offset: 'UTC -03:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Halifax', offset: 'UTC -03:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/Moncton', offset: 'UTC -03:00' },
        { countryCode: 'CL', country: 'Chile', timezone: 'America/Punta_Arenas', offset: 'UTC -03:00' },
        { countryCode: 'CL', country: 'Chile', timezone: 'America/Santiago', offset: 'UTC -03:00' },
        { countryCode: 'FK', country: 'Falkland Islands (Malvinas)', timezone: 'Atlantic/Stanley', offset: 'UTC -03:00' },
        { countryCode: 'GF', country: 'French Guiana', timezone: 'America/Cayenne', offset: 'UTC -03:00' },
        { countryCode: 'GL', country: 'Greenland', timezone: 'America/Thule', offset: 'UTC -03:00' },
        { countryCode: 'SR', country: 'Suriname', timezone: 'America/Paramaribo', offset: 'UTC -03:00' },
        { countryCode: 'UY', country: 'Uruguay', timezone: 'America/Montevideo', offset: 'UTC -03:00' },
        { countryCode: 'CA', country: 'Canada', timezone: 'America/St_Johns', offset: 'UTC -02:30' },
        { countryCode: 'BR', country: 'Brazil', timezone: 'America/Noronha', offset: 'UTC -02:00' },
        { countryCode: 'PM', country: 'Saint Pierre and Miquelon', timezone: 'America/Miquelon', offset: 'UTC -02:00' },
        { countryCode: 'GS', country: 'South Georgia and the South Sandwich Islands', timezone: 'Atlantic/South_Georgia', offset: 'UTC -02:00' },
        { countryCode: 'GB', country: 'United Kingdom', timezone: 'Europe/London', offset: 'UTC +01:00' },
        { countryCode: 'AL', country: 'Albania', timezone: 'Europe/Tirane', offset: 'UTC +02:00' },
        { countryCode: 'AD', country: 'Andorra', timezone: 'Europe/Andorra', offset: 'UTC +02:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Troll', offset: 'UTC +02:00' },
        { countryCode: 'AT', country: 'Austria', timezone: 'Europe/Vienna', offset: 'UTC +02:00' },
        { countryCode: 'BE', country: 'Belgium', timezone: 'Europe/Brussels', offset: 'UTC +02:00' },
        { countryCode: 'BA', country: 'Bosnia and Herzegovina', timezone: 'Europe/Sarajevo', offset: 'UTC +02:00' },
        { countryCode: 'BW', country: 'Botswana', timezone: 'Africa/Gaborone', offset: 'UTC +02:00' },
        { countryCode: 'BI', country: 'Burundi', timezone: 'Africa/Bujumbura', offset: 'UTC +02:00' },
        { countryCode: 'CD', country: 'Congo, the Democratic Republic of the', timezone: 'Africa/Lubumbashi', offset: 'UTC +02:00' },
        { countryCode: 'HR', country: 'Croatia', timezone: 'Europe/Zagreb', offset: 'UTC +02:00' },
        { countryCode: 'CZ', country: 'Czech Republic', timezone: 'Europe/Prague', offset: 'UTC +02:00' },
        { countryCode: 'DK', country: 'Denmark', timezone: 'Europe/Copenhagen', offset: 'UTC +02:00' },
        { countryCode: 'FR', country: 'France', timezone: 'Europe/Paris', offset: 'UTC +02:00' },
        { countryCode: 'DE', country: 'Germany', timezone: 'Europe/Berlin', offset: 'UTC +02:00' },
        { countryCode: 'DE', country: 'Germany', timezone: 'Europe/Busingen', offset: 'UTC +02:00' },
        { countryCode: 'GI', country: 'Gibraltar', timezone: 'Europe/Gibraltar', offset: 'UTC +02:00' },
        { countryCode: 'VA', country: 'Holy See (Vatican City State)', timezone: 'Europe/Vatican', offset: 'UTC +02:00' },
        { countryCode: 'HU', country: 'Hungary', timezone: 'Europe/Budapest', offset: 'UTC +02:00' },
        { countryCode: 'IT', country: 'Italy', timezone: 'Europe/Rome', offset: 'UTC +02:00' },
        { countryCode: 'LS', country: 'Lesotho', timezone: 'Africa/Maseru', offset: 'UTC +02:00' },
        { countryCode: 'LY', country: 'Libya', timezone: 'Africa/Tripoli', offset: 'UTC +02:00' },
        { countryCode: 'LI', country: 'Liechtenstein', timezone: 'Europe/Vaduz', offset: 'UTC +02:00' },
        { countryCode: 'LU', country: 'Luxembourg', timezone: 'Europe/Luxembourg', offset: 'UTC +02:00' },
        { countryCode: 'MK', country: 'Macedonia, the Former Yugoslav Republic of', timezone: 'Europe/Skopje', offset: 'UTC +02:00' },
        { countryCode: 'MW', country: 'Malawi', timezone: 'Africa/Blantyre', offset: 'UTC +02:00' },
        { countryCode: 'MT', country: 'Malta', timezone: 'Europe/Malta', offset: 'UTC +02:00' },
        { countryCode: 'MC', country: 'Monaco', timezone: 'Europe/Monaco', offset: 'UTC +02:00' },
        { countryCode: 'ME', country: 'Montenegro', timezone: 'Europe/Podgorica', offset: 'UTC +02:00' },
        { countryCode: 'MZ', country: 'Mozambique', timezone: 'Africa/Maputo', offset: 'UTC +02:00' },
        { countryCode: 'NA', country: 'Namibia', timezone: 'Africa/Windhoek', offset: 'UTC +02:00' },
        { countryCode: 'NL', country: 'Netherlands', timezone: 'Europe/Amsterdam', offset: 'UTC +02:00' },
        { countryCode: 'NO', country: 'Norway', timezone: 'Europe/Oslo', offset: 'UTC +02:00' },
        { countryCode: 'PL', country: 'Poland', timezone: 'Europe/Warsaw', offset: 'UTC +02:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Europe/Kaliningrad', offset: 'UTC +02:00' },
        { countryCode: 'RW', country: 'Rwanda', timezone: 'Africa/Kigali', offset: 'UTC +02:00' },
        { countryCode: 'SM', country: 'San Marino', timezone: 'Europe/San_Marino', offset: 'UTC +02:00' },
        { countryCode: 'RS', country: 'Serbia', timezone: 'Europe/Belgrade', offset: 'UTC +02:00' },
        { countryCode: 'SK', country: 'Slovakia', timezone: 'Europe/Bratislava', offset: 'UTC +02:00' },
        { countryCode: 'SI', country: 'Slovenia', timezone: 'Europe/Ljubljana', offset: 'UTC +02:00' },
        { countryCode: 'ZA', country: 'South Africa', timezone: 'Africa/Johannesburg', offset: 'UTC +02:00' },
        { countryCode: 'SS', country: 'South Sudan', timezone: 'Africa/Juba', offset: 'UTC +02:00' },
        { countryCode: 'ES', country: 'Spain', timezone: 'Africa/Ceuta', offset: 'UTC +02:00' },
        { countryCode: 'ES', country: 'Spain', timezone: 'Europe/Madrid', offset: 'UTC +02:00' },
        { countryCode: 'SD', country: 'Sudan', timezone: 'Africa/Khartoum', offset: 'UTC +02:00' },
        { countryCode: 'SJ', country: 'Svalbard and Jan Mayen', timezone: 'Arctic/Longyearbyen', offset: 'UTC +02:00' },
        { countryCode: 'SZ', country: 'Swaziland', timezone: 'Africa/Mbabane', offset: 'UTC +02:00' },
        { countryCode: 'SE', country: 'Sweden', timezone: 'Europe/Stockholm', offset: 'UTC +02:00' },
        { countryCode: 'CH', country: 'Switzerland', timezone: 'Europe/Zurich', offset: 'UTC +02:00' },
        { countryCode: 'ZM', country: 'Zambia', timezone: 'Africa/Lusaka', offset: 'UTC +02:00' },
        { countryCode: 'ZW', country: 'Zimbabwe', timezone: 'Africa/Harare', offset: 'UTC +02:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Syowa', offset: 'UTC +03:00' },
        { countryCode: 'BH', country: 'Bahrain', timezone: 'Asia/Bahrain', offset: 'UTC +03:00' },
        { countryCode: 'BY', country: 'Belarus', timezone: 'Europe/Minsk', offset: 'UTC +03:00' },
        { countryCode: 'BG', country: 'Bulgaria', timezone: 'Europe/Sofia', offset: 'UTC +03:00' },
        { countryCode: 'KM', country: 'Comoros', timezone: 'Indian/Comoro', offset: 'UTC +03:00' },
        { countryCode: 'CY', country: 'Cyprus', timezone: 'Asia/Famagusta', offset: 'UTC +03:00' },
        { countryCode: 'CY', country: 'Cyprus', timezone: 'Asia/Nicosia', offset: 'UTC +03:00' },
        { countryCode: 'DJ', country: 'Djibouti', timezone: 'Africa/Djibouti', offset: 'UTC +03:00' },
        { countryCode: 'EG', country: 'Egypt', timezone: 'Africa/Cairo', offset: 'UTC +03:00' },
        { countryCode: 'ER', country: 'Eritrea', timezone: 'Africa/Asmara', offset: 'UTC +03:00' },
        { countryCode: 'EE', country: 'Estonia', timezone: 'Europe/Tallinn', offset: 'UTC +03:00' },
        { countryCode: 'ET', country: 'Ethiopia', timezone: 'Africa/Addis_Ababa', offset: 'UTC +03:00' },
        { countryCode: 'FI', country: 'Finland', timezone: 'Europe/Helsinki', offset: 'UTC +03:00' },
        { countryCode: 'GR', country: 'Greece', timezone: 'Europe/Athens', offset: 'UTC +03:00' },
        { countryCode: 'IL', country: 'Israel', timezone: 'Asia/Jerusalem', offset: 'UTC +03:00' },
        { countryCode: 'JO', country: 'Jordan', timezone: 'Asia/Amman', offset: 'UTC +03:00' },
        { countryCode: 'KE', country: 'Kenya', timezone: 'Africa/Nairobi', offset: 'UTC +03:00' },
        { countryCode: 'KW', country: 'Kuwait', timezone: 'Asia/Kuwait', offset: 'UTC +03:00' },
        { countryCode: 'LV', country: 'Latvia', timezone: 'Europe/Riga', offset: 'UTC +03:00' },
        { countryCode: 'LB', country: 'Lebanon', timezone: 'Asia/Beirut', offset: 'UTC +03:00' },
        { countryCode: 'MG', country: 'Madagascar', timezone: 'Indian/Antananarivo', offset: 'UTC +03:00' },
        { countryCode: 'ML', country: 'Mali', timezone: 'Africa/Bamako', offset: 'UTC +00:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Europe/Samara', offset: 'UTC +04:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Europe/Saratov', offset: 'UTC +04:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Europe/Ulyanovsk', offset: 'UTC +04:00' },
        { countryCode: 'RE', country: 'Réunion', timezone: 'Indian/Reunion', offset: 'UTC +04:00' },
        { countryCode: 'SC', country: 'Seychelles', timezone: 'Indian/Mahe', offset: 'UTC +04:00' },
        { countryCode: 'AE', country: 'United Arab Emirates', timezone: 'Asia/Dubai', offset: 'UTC +04:00' },
        { countryCode: 'AF', country: 'Afghanistan', timezone: 'Asia/Kabul', offset: 'UTC +04:30' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Mawson', offset: 'UTC +05:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Vostok', offset: 'UTC +05:00' },
        { countryCode: 'TF', country: 'French Southern Territories', timezone: 'Indian/Kerguelen', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Almaty', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Aqtau', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Aqtobe', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Atyrau', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Oral', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Qostanay', offset: 'UTC +05:00' },
        { countryCode: 'KZ', country: 'Kazakhstan', timezone: 'Asia/Qyzylorda', offset: 'UTC +05:00' },
        { countryCode: 'MV', country: 'Maldives', timezone: 'Indian/Maldives', offset: 'UTC +05:00' },
        { countryCode: 'PK', country: 'Pakistan', timezone: 'Asia/Karachi', offset: 'UTC +05:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Yekaterinburg', offset: 'UTC +05:00' },
        { countryCode: 'TJ', country: 'Tajikistan', timezone: 'Asia/Dushanbe', offset: 'UTC +05:00' },
        { countryCode: 'TM', country: 'Turkmenistan', timezone: 'Asia/Ashgabat', offset: 'UTC +05:00' },
        { countryCode: 'UZ', country: 'Uzbekistan', timezone: 'Asia/Samarkand', offset: 'UTC +05:00' },
        { countryCode: 'UZ', country: 'Uzbekistan', timezone: 'Asia/Tashkent', offset: 'UTC +05:00' },
        { countryCode: 'IN', country: 'India', timezone: 'Asia/Kolkata', offset: 'UTC +05:30' },
        { countryCode: 'LK', country: 'Sri Lanka', timezone: 'Asia/Colombo', offset: 'UTC +05:30' },
        { countryCode: 'NP', country: 'Nepal', timezone: 'Asia/Kathmandu', offset: 'UTC +05:45' },
        { countryCode: 'BD', country: 'Bangladesh', timezone: 'Asia/Dhaka', offset: 'UTC +06:00' },
        { countryCode: 'BT', country: 'Bhutan', timezone: 'Asia/Thimphu', offset: 'UTC +06:00' },
        { countryCode: 'IO', country: 'British Indian Ocean Territory', timezone: 'Indian/Chagos', offset: 'UTC +06:00' },
        { countryCode: 'CN', country: 'China', timezone: 'Asia/Urumqi', offset: 'UTC +06:00' },
        { countryCode: 'KG', country: 'Kyrgyzstan', timezone: 'Asia/Bishkek', offset: 'UTC +06:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Omsk', offset: 'UTC +06:00' },
        { countryCode: 'CC', country: 'Cocos (Keeling) Islands', timezone: 'Indian/Cocos', offset: 'UTC +06:30' },
        { countryCode: 'MM', country: 'Myanmar', timezone: 'Asia/Yangon', offset: 'UTC +06:30' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Davis', offset: 'UTC +07:00' },
        { countryCode: 'KH', country: 'Cambodia', timezone: 'Asia/Phnom_Penh', offset: 'UTC +07:00' },
        { countryCode: 'CX', country: 'Christmas Island', timezone: 'Indian/Christmas', offset: 'UTC +07:00' },
        { countryCode: 'ID', country: 'Indonesia', timezone: 'Asia/Jakarta', offset: 'UTC +07:00' },
        { countryCode: 'ID', country: 'Indonesia', timezone: 'Asia/Pontianak', offset: 'UTC +07:00' },
        { countryCode: 'LA', country: "Lao People's Democratic Republic", timezone: 'Asia/Vientiane', offset: 'UTC +07:00' },
        { countryCode: 'MN', country: 'Mongolia', timezone: 'Asia/Hovd', offset: 'UTC +07:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Barnaul', offset: 'UTC +07:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Krasnoyarsk', offset: 'UTC +07:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Novokuznetsk', offset: 'UTC +07:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Novosibirsk', offset: 'UTC +07:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Tomsk', offset: 'UTC +07:00' },
        { countryCode: 'TH', country: 'Thailand', timezone: 'Asia/Bangkok', offset: 'UTC +07:00' },
        { countryCode: 'VN', country: 'Viet Nam', timezone: 'Asia/Ho_Chi_Minh', offset: 'UTC +07:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/Casey', offset: 'UTC +08:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Perth', offset: 'UTC +08:00' },
        { countryCode: 'BN', country: 'Brunei Darussalam', timezone: 'Asia/Brunei', offset: 'UTC +08:00' },
        { countryCode: 'CN', country: 'China', timezone: 'Asia/Shanghai', offset: 'UTC +08:00' },
        { countryCode: 'HK', country: 'Hong Kong', timezone: 'Asia/Hong_Kong', offset: 'UTC +08:00' },
        { countryCode: 'ID', country: 'Indonesia', timezone: 'Asia/Makassar', offset: 'UTC +08:00' },
        { countryCode: 'MO', country: 'Macao', timezone: 'Asia/Macau', offset: 'UTC +08:00' },
        { countryCode: 'MY', country: 'Malaysia', timezone: 'Asia/Kuala_Lumpur', offset: 'UTC +08:00' },
        { countryCode: 'MY', country: 'Malaysia', timezone: 'Asia/Kuching', offset: 'UTC +08:00' },
        { countryCode: 'MN', country: 'Mongolia', timezone: 'Asia/Ulaanbaatar', offset: 'UTC +08:00' },
        { countryCode: 'PH', country: 'Philippines', timezone: 'Asia/Manila', offset: 'UTC +08:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Irkutsk', offset: 'UTC +08:00' },
        { countryCode: 'SG', country: 'Singapore', timezone: 'Asia/Singapore', offset: 'UTC +08:00' },
        { countryCode: 'TW', country: 'Taiwan, Province of China', timezone: 'Asia/Taipei', offset: 'UTC +08:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Eucla', offset: 'UTC +08:45' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/DumontDUrville', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Antarctica/Macquarie', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Brisbane', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Hobart', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Lindeman', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Melbourne', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Sydney', offset: 'UTC +10:00' },
        { countryCode: 'GU', country: 'Guam', timezone: 'Pacific/Guam', offset: 'UTC +10:00' },
        { countryCode: 'FM', country: 'Micronesia, Federated States of', timezone: 'Pacific/Chuuk', offset: 'UTC +10:00' },
        { countryCode: 'MP', country: 'Northern Mariana Islands', timezone: 'Pacific/Saipan', offset: 'UTC +10:00' },
        { countryCode: 'PG', country: 'Papua New Guinea', timezone: 'Pacific/Port_Moresby', offset: 'UTC +10:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Ust-Nera', offset: 'UTC +10:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Vladivostok', offset: 'UTC +10:00' },
        { countryCode: 'AU', country: 'Australia', timezone: 'Australia/Lord_Howe', offset: 'UTC +10:30' },
        { countryCode: 'FM', country: 'Micronesia, Federated States of', timezone: 'Pacific/Kosrae', offset: 'UTC +11:00' },
        { countryCode: 'FM', country: 'Micronesia, Federated States of', timezone: 'Pacific/Pohnpei', offset: 'UTC +11:00' },
        { countryCode: 'NC', country: 'New Caledonia', timezone: 'Pacific/Noumea', offset: 'UTC +11:00' },
        { countryCode: 'NF', country: 'Norfolk Island', timezone: 'Pacific/Norfolk', offset: 'UTC +11:00' },
        { countryCode: 'PG', country: 'Papua New Guinea', timezone: 'Pacific/Bougainville', offset: 'UTC +11:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Magadan', offset: 'UTC +11:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Sakhalin', offset: 'UTC +11:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Srednekolymsk', offset: 'UTC +11:00' },
        { countryCode: 'SB', country: 'Solomon Islands', timezone: 'Pacific/Guadalcanal', offset: 'UTC +11:00' },
        { countryCode: 'VU', country: 'Vanuatu', timezone: 'Pacific/Efate', offset: 'UTC +11:00' },
        { countryCode: 'AQ', country: 'Antarctica', timezone: 'Antarctica/McMurdo', offset: 'UTC +12:00' },
        { countryCode: 'FJ', country: 'Fiji', timezone: 'Pacific/Fiji', offset: 'UTC +12:00' },
        { countryCode: 'KI', country: 'Kiribati', timezone: 'Pacific/Tarawa', offset: 'UTC +12:00' },
        { countryCode: 'MH', country: 'Marshall Islands', timezone: 'Pacific/Kwajalein', offset: 'UTC +12:00' },
        { countryCode: 'MH', country: 'Marshall Islands', timezone: 'Pacific/Majuro', offset: 'UTC +12:00' },
        { countryCode: 'NR', country: 'Nauru', timezone: 'Pacific/Nauru', offset: 'UTC +12:00' },
        { countryCode: 'NZ', country: 'New Zealand', timezone: 'Pacific/Auckland', offset: 'UTC +12:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Anadyr', offset: 'UTC +12:00' },
        { countryCode: 'RU', country: 'Russian Federation', timezone: 'Asia/Kamchatka', offset: 'UTC +12:00' },
        { countryCode: 'TV', country: 'Tuvalu', timezone: 'Pacific/Funafuti', offset: 'UTC +12:00' },
        { countryCode: 'UM', country: 'United States Minor Outlying Islands', timezone: 'Pacific/Wake', offset: 'UTC +12:00' },
        { countryCode: 'WF', country: 'Wallis and Futuna', timezone: 'Pacific/Wallis', offset: 'UTC +12:00' },
        { countryCode: 'NZ', country: 'New Zealand', timezone: 'Pacific/Chatham', offset: 'UTC +12:45' },
        { countryCode: 'KI', country: 'Kiribati', timezone: 'Pacific/Kanton', offset: 'UTC +13:00' },
        { countryCode: 'WS', country: 'Samoa', timezone: 'Pacific/Apia', offset: 'UTC +13:00' },
        { countryCode: 'TK', country: 'Tokelau', timezone: 'Pacific/Fakaofo', offset: 'UTC +13:00' },
        { countryCode: 'TO', country: 'Tonga', timezone: 'Pacific/Tongatapu', offset: 'UTC +13:00' },
        { countryCode: 'KI', country: 'Kiribati', timezone: 'Pacific/Kiritimati', offset: 'UTC +14:00' }

    ];




    const timeZones = countryTimezones.map(entry => {
        return {
            timezone: `(${entry.offset}) ${entry.timezone}`
        };
    });


    return (
        <div>
            <div className="form-row-pagetwo">
                <div className="account-type-section">
                    <label className="account-type-label">Account Type:</label>
                    <div className="radio-group">
                        <label>
                            <input
                                type="radio"
                                name="accountType"
                                value="Enterprise"
                                checked={formData.accountType === 'Enterprise'}
                                onChange={(event) => setFormData(prevData => ({
                                    ...prevData,
                                    accountType: event.target.value
                                }))}
                            /> Enterprise
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="accountType"
                                value="Trial"
                                checked={formData.accountType === 'Trial'}
                                onChange={(event) => setFormData(prevData => ({
                                    ...prevData,
                                    accountType: event.target.value
                                }))}
                            /> Trial
                        </label>
                    </div>
                </div>
                <input
                    type="text"
                    className="coupon-input"
                    placeholder="Enter Coupon"
                    value={formData.coupon}
                    onChange={(event) => setFormData(prevData => ({
                        ...prevData,
                        coupon: event.target.value
                    }))}
                />
            </div>
            {formData.accountType === 'Enterprise' && (
                <div className="form-row">
                    <input
                        type="text"
                        className="company-input"
                        placeholder="Enter Company Name"
                        value={formData.companyname}
                        onChange={(event) => setFormData(prevData => ({
                            ...prevData,
                            companyname: event.target.value
                        }))}
                    /> </div>)}
            <div className="form-row">

                <div className="timezone-selector">
                    <div class="left">
                        <select
                            className="region-dropdown"
                            value={formData.regionname}
                            onChange={(event) => {
                                const selectedRegion = event.target.value;
                                if (selectedRegion === 'Europe' || selectedRegion === 'Asia') {
                                    showAlert("We are coming up with these DC's in this region.", 'warning');
                                    return;
                                }
                                setFormData((prevData) => ({
                                    ...prevData,
                                    regionname: selectedRegion,
                                }));

                            }}>
                            <option value="">DataCenter Region</option>
                            <option value="US">US</option>
                            <option value="Europe">Europe</option>
                            <option value="Asia">Asia</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div class="right">
                        <select
                            className="timezone-dropdown"
                            value={formData.timezone}
                            onChange={(event) => setFormData({ ...formData, timezone: event.target.value })}
                        >
                            <option value="" disabled>System Time Zone</option>
                            {timeZones.map((entry, index) => (
                                <option key={index} value={entry.timezone}>
                                    {entry.timezone}
                                </option>
                            ))}
                        </select>
                        <div className="info-icon">
                            ℹ️
                            <span className="tooltip-text">This timezone will be used for displaying client specific time/timezone.</span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default RegistrationForm;
