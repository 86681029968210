import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import IntegrationSolution from './Pages/IntegrationSolutionPage';
import AutomationTools from './Pages/AutomationTools'
import ComprehensiveResourcing from './Pages/ComprehensiveResourcing'
import LoginPage from './Pages/Login/LoginPage';
import RegistrationPage from './Pages/Registration/RegistrationPage';
import { AlertProvider } from './ProHelpers/AlertContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/Login",
    element: <LoginPage />,
  }, {
    path: "/Register",
    element: <RegistrationPage />,
  }
  //  {
  //   path: "IntegrationService",
  //   element: <IntegrationSolution />,
  // }, {
  //   path: "AutomationTools",
  //   element: <AutomationTools />,
  // }, {
  //   path: "ComprehensiveResourcing",
  //   element: <ComprehensiveResourcing />,
  // }
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AlertProvider>
      <RouterProvider router={router} />
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
