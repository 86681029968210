import React, { useState } from 'react';
import openEye from '../../Resource/openeye.jpeg';
import closeEye from '../../Resource/closeeye.jpeg';


function RegistrationPage3({ formData, setFormData }) {
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div>
            <div className="form-data-summary scrollable-section">
                <div className="summary-item row">
                    <div className="summary-item">Account Type: <b>{formData.accountType}</b>  {formData.accountType === 'Enterprise' && (
                        <b>{formData.companyname}</b>
                    )}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">Name: {formData.firstname} {formData.lastname}</div>

                </div>
                <div className="summary-item row">
                    <div className="summary-item">Address: {formData.address}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">Country: {formData.countryname}</div>
                    <div className="summary-item">DC Region: {formData.regionname}</div>
                </div>
                <div className="summary-item row">
                    <div className="summary-item">System Time Zone: {formData.timezone}</div>
                </div>
            </div>

            <div className="form-row-pagethree">
                <input type="email" placeholder="Enter Email" value={formData.email} onChange={(event) => {
                    setFormData({ ...formData, email: event.target.value })

                }} />

            </div>
            <div className="password-input-container">
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    value={formData.password}
                    onChange={(event) => {
                        setFormData({ ...formData, password: event.target.value })

                    }} />
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    value={formData.confirmpassword}
                    onChange={(event) => {
                        setFormData({ ...formData, confirmpassword: event.target.value })

                    }} />
                <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                    {passwordVisible ? (
                        <img src={openEye} alt="Hide Password" />
                    ) : (
                        <img src={closeEye} alt="Show Password" />
                    )}
                </button>
            </div>

        </div >
    )
}

export default RegistrationPage3