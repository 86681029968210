import React, { useState } from 'react';
import './RegistrationPage.css';
import logo from '../../Resource/logo.png'; // Add your logo image here
import registrationImage from '../../Resource/registrationImage.png'; // Add your image here
import RegistrationPage1 from './RegistrationPage1';
import RegistrationPage2 from './RegistrationPage2';
import RegistrationPage3 from './RegistrationPage3';
import { Link } from 'react-router-dom';
import { useAlert } from '../../ProHelpers/AlertContext'; // Import the useAlert hook

const RegistrationPage = () => {
    const { showAlert } = useAlert();

    const [page, setPage] = useState(0);
    const [transition, setTransition] = useState('slide-to-center'); // Initial transition state
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        address: "",
        countryname: "",
        accountType: "Trial",
        coupon: "",
        companyname: "",
        regionname: "",
        timezone: "",
        email: "",
        password: "",
        confirmpassword: "",
    });
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const FormTitles = ["Basic Information", "System Information", "Account Details"];

    const PageDisplay = () => {
        if (page === 0) {
            return <RegistrationPage1 formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <RegistrationPage2 formData={formData} setFormData={setFormData} />;
        } else if (page === 2) {
            return <RegistrationPage3 formData={formData} setFormData={setFormData} />;
        }
    }
    // Function to validate password
    const validatePassword = (password) => {
        // Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        return pattern.test(password);
    };

    // Function to validate email format
    const validateEmail = (email) => {
        // Basic email format validation
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };


    // Validation function to check if all required fields are filled based on the current page
    const validatePage = () => {
        switch (page) {
            case 0:
                return formData.firstname && formData.lastname && formData.address && formData.countryname;
            case 1:
                const isAccountTypeValid = formData.accountType === 'Trial' || (formData.accountType === 'Enterprise' && formData.companyname);
                return formData.coupon && formData.regionname && formData.timezone && isAccountTypeValid;
            case 2:
                if (!validateEmail(formData.email)) {
                    setTimeout(() => showAlert('Invalid email format!', 'failure'), 0);
                    return false;
                }
                if (!validatePassword(formData.password)) {
                    setTimeout(() => showAlert('Password Must Contain at least 8 characters, One Uppercase Letter, One Number, and One Special Character..', 'failure'), 0);
                    return false;
                }
                if (formData.password !== formData.confirmpassword) {
                    setTimeout(() => showAlert('Oops! Looks like the Confirm Passwords You Entered Don\'t Match. Try again?', 'failure'), 0);
                    return false;
                }
                return formData.email && formData.password && formData.confirmpassword;

            default:
                return false;
        }
    };


    const isButtonDisabled = () => {
        if (page === FormTitles.length - 1) {
            // On the last page, check both terms acceptance and form validation
            return !isTermsChecked || !validatePage();
        } else {
            // For other pages, just check form validation
            return !validatePage();
        }
    };


    // Check if the button should be disabled based on validation
    //const isButtonDisabled = page === FormTitles.length - 1 ? !isTermsChecked && !validatePage() : !validatePage();

    const handlePageChange = (newPage, direction) => {
        // Set the appropriate slide-out class based on the direction
        setTransition(direction === 'next' ? 'slide-in-left' : 'slide-out-left');

        setTimeout(() => {
            // Update the page number and set the incoming transition class
            setPage(newPage);
            setTransition(direction === 'next' ? 'slide-out-right' : 'slide-in-left');

            // Reset the transition to center after animation completes
            setTimeout(() => {
                setTransition('slide-to-center');
            }, 200); // Match the duration of the CSS transition
        }, 200); // Match the duration of the CSS transition
    };

    return (
        <div className="registration-page">
            <div className="registration-card">
                <div className="registration-image">
                    <div className="logo">
                        <Link to="/"><img src={logo} alt="Logo" /></Link>
                    </div>
                    <img src={registrationImage} alt="Registration" />
                </div>
                <div className="registration-form-container">
                    <h2 className="form-title">Create Account</h2>
                    <div className="header"><h2 className="form-sub-title">{FormTitles[page]}</h2></div>
                    <div className="body-container">
                        <div className={`body ${transition}`}>
                            {PageDisplay()}
                        </div>
                    </div>
                    <div className="form-button-row">
                        {page === 2 ? (
                            <div className="terms-checkbox">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    checked={isTermsChecked}
                                    onChange={(event) => setIsTermsChecked(event.target.checked)}
                                />
                                <label htmlFor="terms">I Accept the Terms and Conditions</label>
                            </div>
                        ) : ""}

                        {page > 0 && (
                            <button
                                className="register-btn"
                                onClick={() => handlePageChange(page - 1, 'previous')}
                            >
                                Previous
                            </button>
                        )}
                        <button
                            className="register-btn"
                            onClick={() => {
                                if (page === FormTitles.length - 1) {
                                    showAlert('Success! Successfully form submitted.', 'success');
                                } else {
                                    handlePageChange(page + 1, 'next');
                                }
                            }}
                            disabled={isButtonDisabled()} // Disable button based on validation
                        >
                            {page === FormTitles.length - 1 ? "Create Account" : "Next"}
                        </button>
                    </div>
                    <div className="login-link">
                        <p>Already have an account? <Link to="/Login">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;
