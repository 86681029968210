import React, { useState } from 'react';
import './LoginPage.css';
import logo from '../../Resource/logo.png'; // Add your logo image here
import loginImage from '../../Resource/loginImage.png'; // Add your image here
import { Link } from 'react-router-dom'; // Import Link for navigation
import openEye from '../../Resource/openeye.jpeg';
import closeEye from '../../Resource/closeeye.jpeg';

const LoginPage = () => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    return (
        <div className="login-page">
            <div className="login-card">
                <div className="login-card-left">
                    <img src={loginImage} alt="Login" className="login-image" />
                    <Link to="/"><img src={logo} alt="Logo" className="logo" /></Link>
                </div>
                <div className="login-card-right">
                    <h2 className="login-title">Sign In</h2>
                    <form className="login-form">
                        <div className="input-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" placeholder="Enter your email" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Password</label>
                            <div className="password-wrapper">
                                <input type={passwordVisible ? 'text' : 'password'} id="password" name="password" placeholder="Enter your password" />
                                <button type="button" className="show-hide-btn" onClick={togglePasswordVisibility}>
                                    {passwordVisible ? (
                                        <img src={openEye} alt="Hide Password" />
                                    ) : (
                                        <img src={closeEye} alt="Show Password" />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="login-btn-container">
                            <button type="submit" className="login-btn-onpage">Login</button>
                        </div>
                    </form>
                    <div className="register-link">
                        <p>Don't have an account? <Link to="/Register">Create account</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;

